import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Immobilienblase = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Ist die Angst vor einer Immobilienblase berechtigt?" showCalc={false} />
            <Article>
                <p>
                    Viele Experten warnen momentan vor einer Immobilienblase, die platzen könnte. Das kann einen
                    ziemlich verunsichern, vor allem auch in Hinblick auf die noch unvorhersehbaren Auswirkungen der
                    Pandemie. Deshalb schauen wir uns das Thema in diesem Artikel genauer an. Wir erklären dir, was eine
                    Immobilienblase ist, was es bedeutet, wenn die Blase platzt und wie realistisch das momentan
                    wirklich ist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Immobilienblase?</h2>
                <p>
                    Eine Immobilienblase entsteht, wenn Immobilien auf dem Markt enorm überbewertet werden. Dann steigen
                    die Immobilienpreise über einen längeren Zeitraum unverhältnismäßig schnell und stark an. Die
                    Nachfrage steigt, aber das Angebot bleibt begrenzt und die Blase wächst schnell an, bis sie
                    irgendwann platzt und die Preise wieder sinken. Damit sie platzt, müssen allerdings mehrere
                    unterschiedliche Faktoren eintreten.
                </p>
                <hr />

                <h2>Wie kann es zu einer Immobilienblase kommen?</h2>
                <p>
                    Es gibt unterschiedliche Gründe, die zur Entstehung einer Immobilienblase führen. Diese Gründe sind
                    ziemlich vielschichtig und hängen oft eng miteinander zusammen:
                </p>
                <ul>
                    <li>
                        <strong>Spekulation:</strong> Investoren, die am Immobilienboom verdienen möchten, kaufen
                        Liegenschaften, um sie mit möglichst viel Gewinn wieder zu verkaufen. Viele dieser
                        Liegenschaften stehen außerdem lange leer, was vor allem in Städten die Preise steigen lässt
                        weil das Angebot begrenzt ist, aber eine große Nachfrage nach Wohnraum besteht.
                    </li>
                    <li>
                        <strong>Steigende Nachfrage bei niedrigen Zinsen:</strong> Wenn die Zinsen niedrig sind, kaufen
                        sich mehr Menschen Immobilien und die Nachfrage steigt. Allerdings können auch die Zinsen wieder
                        steigen und viele sich die monatliche Rate danach nicht mehr leisten. Das ist auch eine Gefahr
                        für das Bankensystem.
                    </li>
                    <li>
                        <strong>Fiskalpolitische Maßnahmen:</strong> Auch der Staat kann Angebot und Nachfrage z.B.
                        durch steuerliche Begünstigungen auf dem Markt negativ beeinflussen.
                    </li>
                    <li>
                        <strong>Bevölkerungswachstum:</strong> Steigende Bevölkerungszahlen können eine Immobilienblase
                        befeuern, weil immer mehr Menschen Wohnraum benötigen und die Nachfrage bei zu geringer
                        Bautätigkeit einen Druck auf die Preise ausübt.
                    </li>
                    <li>
                        <strong>Es wird zu wenig gebaut:</strong> Vor allem in großen Städten wird Bauland immer
                        knapper. Das bedeutet, dass nicht mehr ausreichend neuer Wohnraum geschaffen werden kann, um der
                        hohen Nachfrage zu begegnen.
                    </li>
                </ul>
                <p>
                    Wenn die Immobilienpreise von ihrem Sachwert und den Mietpreisen entkoppelt werden, ist das ein
                    wichtiges Anzeichen für eine wachsende Immobilienblase. Während die Mietpreise nur wenig steigen
                    oder stagnieren, sind Käufer*innen bereit, stark überhöhte Kaufpreise zu bezahlen. Wenn sich das
                    Lohnniveau gleichzeitig kaum verändert, kann das dazu führen, dass Kreditnehmer ihren Kredit nicht
                    mehr zurückzahlen können.
                </p>
                <p>
                    Zu einem bestimmten Zeitpunkt erreichen die Preissteigerungen dann schließlich ihren Höhepunkt und
                    die Preise beginnen wieder zu sinken. Wenn ein plötzlicher Preisverfall eintritt, spricht man vom
                    Platzen der Immobilienblase. Wenn die Blase platzt, setzt das oft eine Abwärtsspirale in Gang: der
                    Preissturz beginnt und kann zu Panikverkäufe führen, die einen konstanten Preisverfall auslösen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was passierte 2007 in Amerika?</h2>
                <p>
                    Genau das ist in Amerika 2007 passiert und hatte 2008 eine Weltwirtschaftskrise zur Folge. Der Grund
                    waren aber nicht einfach stark fallende Immobilienpreise. In den USA war die Ursache für die
                    Immobilienblase, dass immer mehr Kreditnehmer mit sehr geringer Bonität einen Immobilienkredit
                    aufgenommen hatten. Damals gaben die Banken Kredite viel zu leichtfertig her. Durch die steigende
                    Nachfrage sind die Preise immer weiter gestiegen, während gleichzeitig immer mehr Kredite vergeben
                    wurden. Es entstand eine Preisspirale. Weil weder der Staat noch die Banken eingegriffen haben,
                    heizte sich der Markt künstlich immer weiter auf. Die Kredite wurden immer größer und die Menschen
                    konnten sie nicht mehr zurückzahlen. Dadurch gerieten wiederum die Banken in finanzielle
                    Schwierigkeiten.Schließlich ist die Blase geplatzt, was schlimme Folgen für die Kreditnehmer und das
                    Bankensystem hatte.
                </p>
                <hr />

                <h2>Kann das in Europa auch passieren?</h2>
                <p>
                    Grundsätzlich kann es überall zu einer Immobilienblase kommen. Aber in Europa, und vor allem in
                    Deutschland und Österreich, sind die Anforderungen an Kreditnehmer deutlich höher als damals in den
                    USA. Mit einer zu geringen{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    erhält man hier keinen Kredit.
                </p>
                <p>
                    In Deutschland und Österreich steigen die Immobilienpreise schon seit Jahren, das ist klar spürbar.
                    Ob uns eine Immobilienblase droht, ist allerdings umstritten. Viele Experten und Expertinnen warnen
                    davor, dass es zu einer kommen könnte, andere meinen, dass Deutschland und Österreich im Vergleich
                    zu anderen europäischen Staaten noch weit davon entfernt sind.
                </p>
                <hr />

                <h2>Soll ich abwarten, bis die Blase platzt?</h2>
                <p>
                    Gute Frage. Es kommt auch hier ganz darauf an, wen du fragst. Experten, die vor einer bevorstehenden
                    Immobilienblase warnen, werden dir meistens davon abraten. Diejenigen, die die Angst vor der Blase
                    für unberechtigt halten, werden dir dazu raten, jetzt in eine Immobilie zu investieren, weil die
                    Zinsen aktuell niedrig sind.
                </p>
                <p>
                    Wir finden, dass man nicht jedem das gleiche Raten kann. Wir müssen deine individuelle Situation
                    betrachten, denn für manche ist es aktuell ungefährlich in eine Immobilie zu investieren, während es
                    für manche besser wäre, abzuwarten.
                </p>
                <p>
                    Schuldenfreie Immobilienbesitzer*innen haben beispielsweise im Falle einer Immobilienblase überhaupt
                    nichts zu befürchten. Aber auch wenn du die Immobilie lediglich für deinen Eigenbedarf kaufst, einen
                    Kredit abbezahlst und die monatlichen Raten nicht allzu hoch angesetzt hast, kannst du einige Zeit
                    mit den etwas erhöhten Raten umgehen, bis sich die Situation wieder beruhigt hat. Es ist auch von
                    Bedeutung welches Zinsmodell du mit der Bank vereinbart hast.
                </p>
                <p>
                    Über all diese Dinge muss man sprechen, um die Gefahr richtig einzuschätzen. Genauso wie du über die
                    Situation am Markt informiert sein musst, musst du die Gefahr für dich selbst richtig einschätzen
                    können. Fakt ist, dass die Investition in “Betongold” immer noch für viele Menschen eine der
                    sichersten Varianten darstellt, um ihr Geld anzulegen.
                </p>
                <hr />

                <h2>Egal, was passiert: Lass dich jetzt beraten.</h2>
                <p>
                    Gerade wenn du dir noch nicht sicher bist, ob du jetzt eine Immobilie kaufen sollst oder noch warten
                    willst: Die richtige Vorbereitung schadet nie. Beschäftige dich mit den Themen Finanzierung, Kredit
                    und{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>
                    . Verfolge die Nachrichten. Und sprich lieber zu früh als zu spät mit einem Finanzberater über deine
                    Pläne. Wer weiß, vielleicht kommt der Zeitpunkt für dich ja doch früher als du denkst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin />

            <BreadcrumbList page={"immobilienblase"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immobilienblase"}
                heading={"Ist die Angst vor einer Immobilienblase berechtigt?"}
            />
        </Layout>
    );
};

export default Immobilienblase;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.artikel.immobilienblase"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
